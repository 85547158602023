<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.baslik }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div v-if="mainData.durum == 2" class="grid">
			<div class="col-12">
				<div class="card" style="background-color: #ff6b6b; color:white">
					<h5>KAYBEDİLDİ</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Kapatıldığı Tarih</strong>
								<p>{{ kapanisData.kapatildigiTarih }}</p>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Rakip</strong>
								<p>{{ kapanisData.rakip }}</p>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Gerçek Gelir</strong>
								<p>{{ formatNumber(kapanisData.gercekGelir) }}</p>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<strong>Kapanış Açıklama</strong>
								<p>{{ kapanisData.kapanisAciklama }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="mainData.durum == 1" class="grid">
			<div class="col-12">
				<div class="card" style="background-color: #1dd1a1; color:white">
					<h5>KAZANILDI</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Kapatıldığı Tarih</strong>
								<p>{{ kapanisData.kapatildigiTarih }}</p>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<strong>Gerçek Gelir</strong>
								<p>{{ formatNumber(kapanisData.gercekGelir) }}</p>
							</div>
						</div>
						<div class="col-8">
							<div class="field p-fluid">
								<strong>Kapanış Açıklama</strong>
								<p>{{ kapanisData.kapanisAciklama }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="SATIŞ PROJESİ" entityName="opportunity"></EntityHeader>
					
					<div class="grid">
						<div class="col-12">
							<div class="field p-fluid">
								<label for="baslik">Başlık <span style="color:red">*</span></label>
								<InputText id="baslik" type="text" v-model="mainData.baslik" :disabled="isDisabled" />
								<span v-if="v$.mainData.baslik.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.baslik.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="potansiyelMusteri" v-model="mainData.potansiyelMusteriName" ref="entity_potansiyelmusteri"
									label="Potansiyel Müşteri" entityName="account" nameField="name" :state="true" :required="true" :disabled="isDisabled"
									@itemSelected="potansiyelMusteriSelected = $event" @itemCleared="potansiyelMusteriSelected = null"></EntityLookup>
								<span v-if="v$.mainData.potansiyelMusteriName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.potansiyelMusteriName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<EntityLookup id="ilgilikisi" v-model="mainData.ilgiliKisiName" ref="entity_ilgilikisi"
									label="İlgili Kişi" entityName="contact" nameField="fullname" secondNameField="bm_unvan" secondNameFieldType="picklist" :state="true" :required="true" :disabled="isDisabled"
									parentFieldName="parentcustomerid" :parentId="potansiyelMusteriSelected"
									@itemSelected="ilgiliKisiSelected = $event" @itemCleared="ilgiliKisiSelected = null"></EntityLookup>
								<span v-if="v$.mainData.ilgiliKisiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.ilgiliKisiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="firmaadi">Açıklama</label>
								<Textarea id="firmaadi" rows="2" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
							</div>
						</div>
						<div class="col-6">
							<div class="field p-fluid">
								<label for="durum">Durum</label>
								<Dropdown id="durum" v-model="mainData.durum" :options="SM_Durum" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum" :showClear="true" :optionDisabled="true" disabled />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="marka">Ürün Marka <span style="color:red">*</span></label>
								<Dropdown id="marka" v-model="mainData.urunMarka" :options="SM_Marka" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Marka" :filter="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.urunMarka.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.urunMarka.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bolge">Bölge</label>
								<Dropdown id="bolge" v-model="mainData.bolge" :options="SM_bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :disabled="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card">
					<h5>TAHMİN BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-2">
							<div class="field p-fluid">
								<label for="kapanisTarihi">Kapanış Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="kapanisTarihi" v-model="mainData.kapanisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.kapanisTarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.kapanisTarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<EntityLookup id="parabirimi" v-model="mainData.paraBirimiName" ref="entity_parabirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="currencyname" :state="true" :required="true" :disabled="isDisabled" 
									@itemSelected="paraBirimiSelected = $event" @itemCleared="paraBirimiSelected = null"></EntityLookup>
								<span v-if="v$.mainData.paraBirimiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.paraBirimiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="tezgahAdedi">Tezgah Adedi <span style="color:red">*</span></label>
								<InputNumber id="tezgahAdedi" v-model="mainData.tezgahAdedi" mode="decimal" locale="tr-TR" :minFractionDigits="0" :min="0" :showButtons="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.tezgahAdedi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahAdedi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<CrmSubject ref="subject_tezgahtipi" label="Tezgah Tipi" @itemSelected="TezgahTipiSelect" :required="true" :disabled="isDisabled"/>
								<span v-if="v$.mainData.tezgahTipiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.tezgahTipiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="projeninDurumu">Projenin Durumu <span style="color:red">*</span></label>
								<Dropdown id="projeninDurumu" v-model="mainData.projeninDurumu" :options="SM_ProjeninDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="Projenin Durumu" :showClear="true" :disabled="isDisabled" />
								<span v-if="v$.mainData.projeninDurumu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.projeninDurumu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<h5>TERCİHLER</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="kayitSahibi" v-model="mainData.kayitSahibiName" ref="entity_kayitSahibi"
									label="Kayıt Sahibi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true" :disabled="isDisabled" 
									@itemSelected="kayitSahibiSelected = $event" @itemCleared="kayitSahibiSelected = null"
									secondNameField="bm_bolge" secondNameFieldType="picklist"
									parentFieldName="bm_anadepartman" :parentId="{'Value': 1000}">
								</EntityLookup>
								<span v-if="v$.mainData.kayitSahibiName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.kayitSahibiName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="card">
					<TabView :scrollable="true" lazy>
						<!-- TEKLİFLER -->
						<TabPanel v-if="isTekliflerYetki">
							<template #header>
								<div class="cyan-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">TEKLİFLER</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_teklif }} adet</span>
										</div>
									</div>
								</div>
							</template>
							<Button icon="pi pi-plus" class="p-button-secondary" label="Teklif Ekle" @click="TeklifEkle()" :disabled="isDisabled" />

							<CrmDataTable :key="CrmDataTable_quote_key" baseEntityName="quote" :options="CrmDataTable_quote_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- SİPARİŞLER -->
						<TabPanel v-if="isSiparislerYetki">
							<template #header>
								<div class="pink-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SİPARİŞLER</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{mainData.adet_siparis}} adet</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_salesorder_key" baseEntityName="salesorder" :options="CrmDataTable_salesorder_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true"  />
						</TabPanel>

						<!-- RANDEVULAR -->
						<TabPanel v-if="isRandevularYetki">
							<template #header>
								<div class="deeporange-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">RANDEVULAR</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{mainData.adet_randevu}} adet</span>
										</div>
									</div>
								</div>
							</template>
							<Button icon="pi pi-plus" class="p-button-secondary" label="Randevu Ekle" @click="OnRandevuEkle()" :disabled="isDisabled" />
							<CrmDataTable :key="CrmDataTable_appointment_key" baseEntityName="appointment" :options="CrmDataTable_appointment_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true"  />
						</TabPanel>

						<!-- TEL. GÖRÜŞMESİ -->
						<TabPanel v-if="isTelefonGorusmeleriYetki">
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-shopping-cart"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">TELEFON GÖRÜŞMELERİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{mainData.adet_telefongorusmesi}} adet</span>
										</div>
									</div>
								</div>
							</template>
							<Button icon="pi pi-plus" class="p-button-secondary" label="Telefon Görüşmesi Ekle" @click="OnTelefonGorusmesiEkle()" :disabled="isDisabled" />

							<CrmDataTable :key="CrmDataTable_phonecall_key" baseEntityName="phonecall" :options="CrmDataTable_phonecall_options" :rows="10"
								:isHeaderVisible="true" :doubleClickOpenEntity="true"  />
						</TabPanel>

						<!-- NOTLAR -->
						<TabPanel>
							<template #header>
								<div class="orange-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-file"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">DÖKÜMAN YÖNETİMİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_notlar}} adet</span>
										</div>
									</div>
								</div>
							</template>
							<Annotation objectTypeCode="3" logicalName="opportunity" :objectId="entityId" ></Annotation>
						</TabPanel>
					</TabView>
				</div>
			</div>

			<Dialog header="Fırsatı Kapat" v-model:visible="displayFirsatiKapat" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" :modal="true">
				<br/>
				
				<div class="grid">
					<div class="col-12">
						<div class="field p-fluid">
							<div class="flex flex-wrap gap-3">
								<div class="flex align-items-center">
									<RadioButton inputId="durum" name="city" :value="1" v-model="dataFirsatiKapat.state" @change="changeDurum"/>
									<label for="durum"> Kazanıldı</label>
								</div>
								<div class="flex align-items-center ml-4">
									<RadioButton inputId="durum" name="city" :value="2" v-model="dataFirsatiKapat.state" @change="changeDurum" />
									<label for="durum"> Kaybedildi</label>
								</div>
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="field p-fluid">
							<label for="durumAciklamasi">Durum Açıklaması</label>
							<Dropdown id="durumAciklamasi" v-model="dataFirsatiKapat.statusCode" :options="SM_statuscode(dataFirsatiKapat.state)"
										optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması"
										:showClear="true" :filter="true"  />
						</div>
					</div>

					<div class="col-6">
						<div class="field p-fluid">
							<label for="gercekGelir">Gerçek Gelir</label>
							<InputNumber id="gercekGelir" v-model="dataFirsatiKapat.actualrevenue" mode="decimal" locale="tr-TR" :minFractionDigits="2" />
						</div>
					</div>

					<div class="col-6">
						<div class="field p-fluid">
							<label for="kapanisTarihi">Kapanış Tarihi</label>
							<Calendar showButtonBar id="kapanisTarihi" v-model="dataFirsatiKapat.actualEnd" autocomplete="off" :showIcon="true" />
						</div>
					</div>

					<div class="col-6" v-if="dataFirsatiKapat.state == 2">
						<div class="field p-fluid">
							<EntityLookup id="rakip" v-model="dataFirsatiKapat.competitor" ref="entity_rakip"
								label="Rakip" entityName="competitor" nameField="name"
								@itemSelected="rakipSelected = $event" @itemCleared="rakipSelected = null">
							</EntityLookup>
						</div>
					</div>

					<div class="col-12">
						<div class="field p-fluid">
							<label for="aciklama">Açıklama</label>
							<Textarea id="aciklama" rows="2" :autoResize="false" v-model="dataFirsatiKapat.description" />
						</div>
					</div>
				</div>
				<template #footer>
					<Button label="Fırsatı Kapat" class="p-button-danger" icon="pi pi-save" @click="OnClickFirsatiKapat" />
				</template>
			</Dialog>

		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import user from '../store/user';

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 3,
			mainData: {},
			kapanisData: {},

			displayFirsatiKapat: false,
			dataFirsatiKapat: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					visible: () => !this.isDisabled,
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Randevu Ekle',
					icon:'pi pi-fw pi-plus',
					visible: () => !this.isDisabled,
					command: () => { this.OnRandevuEkle(); },
				},
				{
					label:'Tel Gör. Ekle',
					icon:'pi pi-fw pi-plus',
					visible: () => !this.isDisabled,
					command: () => { this.OnTelefonGorusmesiEkle(); },
				},
				{
					label:'Kazandı Olarak Kapat',
					icon:'pi pi-fw pi-heart',
					visible: () => !this.isDisabled,
					command: () => { this.OnFirsatiKapat(1); },
				},
				{
					label:'Kayıp Olarak Kapat',
					icon:'pi pi-fw pi-ban',
					visible: () => !this.isDisabled,
					command: () => { this.OnFirsatiKapat(2); },
				},
				{
					label:'Fırsatı Yeniden Aç',
					icon:'pi pi-fw pi-history',
					visible: () => this.mainData.durum != 0,
					command: () => { this.OnFirsatiYenidenAc(); },
				},
			],
			tezgahTipiNodes: [],

			CrmDataTable_quote_key: 0,
			CrmDataTable_quote_options: {
				searchAttributes: ["quotenumber", "customeridname", "bm_uniteidname", "bm_urunidname"],
				filterParent: [
					{ 'name': 'opportunityid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010013100',
				views: [
					{ 'viewName': 'Tüm Teklifler', 'savedQueryId': '00000000-0000-0000-00AA-000010013100' },
				],
				preventSystemViews: true,
				preventUserViews: true
			},

			CrmDataTable_salesorder_key: 0,
			CrmDataTable_salesorder_options: {
				searchAttributes: ["ordernumber", "customeridname", "bm_frmno", "bm_urunidname"],
				filterParent: [
					{ 'name': 'opportunityid', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: '71F7DA6E-2772-4097-89D1-3670FF8348BB',
				views: [
					{ 'viewName': 'Sipariş İlişkilendirilmiş Görünümü', 'savedQueryId': '71F7DA6E-2772-4097-89D1-3670FF8348BB' },
				]
			},

			CrmDataTable_appointment_key: 0,
			CrmDataTable_appointment_options: {
				searchAttributes: ["bm_name", "bm_firmaidname"],
				filterParent: [
					{ 'name': 'bm_satisprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
			},

			CrmDataTable_phonecall_key: 0,
			CrmDataTable_phonecall_options: {
				searchAttributes: ["subject", "regardingobjectidname"],
				filterParent: [
					{ 'name': 'bm_satisprojesi', 'filterOperator': 'eq', 'value': this.entityId },
				],
				forceSavedQueryId: 'F6A2F891-6846-4B21-B922-712695AFD65C'
			},
		}
	},
	async created() {
		this.crmService = new CrmService();

		console.log('created');
		window.addEventListener('scroll', this.handleScroll);

		window.addEventListener("message", (event)=>{
			var origin = event.origin;
			debugger;
			if (window.origin == origin && event.isTrusted && event.data.tag != undefined) {
				debugger;
				if (event.data.tag == "refresh") {
					this.CrmDataTable_appointment_key++;
				}
				else if (event.data.tag == "opportunity_reload") {
					debugger;
					this.OnLoad();
				}
			}
		});

        this.OnLoad();
    },
    mounted() {

    },
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDisabled: function() {
			let _yetkiVarmi = false;
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Satış Projeleri');
					if (filtered.length > 0) {
						_yetkiVarmi = filtered[0].isWrite == true;
					}
				}
			}

			let _kapalimi = this.mainData.durum != 0
			
			if (_yetkiVarmi == false || _kapalimi) {
				return true;
			}
			else {
				return false;
			}
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Satış Projeleri');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		isSiparislerYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Siparişler');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isTekliflerYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Teklifler');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isRandevularYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Randevular');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isTelefonGorusmeleriYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Telefon Görüşmeleri');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		
		SM_Durum: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statecode');
		},
		SM_Marka: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunmarka');
		},
		SM_ProjeninDurumu: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_projenindurumu');
		},
		SM_bolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bolge');
		},
		potansiyelMusteriSelected: {
			get: function () {
				if (this.mainData["potansiyelMusteriId"]) {
					return { "Value": this.mainData["potansiyelMusteriId"], "Name": this.mainData["potansiyelMusteriName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["potansiyelMusteriId"] = null;
					this.mainData["potansiyelMusteriName"] = null;
				}
				else {
					this.mainData["potansiyelMusteriId"] = newValue.Value;
					this.mainData["potansiyelMusteriName"] = newValue.Name;
				}
			}
		},
		ilgiliKisiSelected: {
			get: function () {
				if (this.mainData["ilgiliKisiId"]) {
					return { "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["ilgiliKisiId"] = null;
					this.mainData["ilgiliKisiName"] = null;
				}
				else {
					this.mainData["ilgiliKisiId"] = newValue.Value;
					this.mainData["ilgiliKisiName"] = newValue.Name;
				}
			}
		},
		paraBirimiSelected: {
			get: function () {
				if (this.mainData["paraBirimiId"]) {
					return { "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["paraBirimiId"] = null;
					this.mainData["paraBirimiName"] = null;
				}
				else {
					this.mainData["paraBirimiId"] = newValue.Value;
					this.mainData["paraBirimiName"] = newValue.Name;
				}
			}
		},
		kayitSahibiSelected: {
			get: function () {
				if (this.mainData["kayitSahibiId"]) {
					return { "Value": this.mainData["kayitSahibiId"], "Name": this.mainData["kayitSahibiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["kayitSahibiId"] = null;
					this.mainData["kayitSahibiName"] = null;
				}
				else {
					this.mainData["kayitSahibiId"] = newValue.Value;
					this.mainData["kayitSahibiName"] = newValue.Name;
				}
			}
		},
		rakipSelected: {
			get: function () {
				if (this.dataFirsatiKapat["competitorId"]) {
					return { "Value": this.dataFirsatiKapat["competitorId"], "Name": this.dataFirsatiKapat["competitorName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataFirsatiKapat["competitorId"] = null;
					this.dataFirsatiKapat["competitorName"] = null;
				}
				else {
					this.dataFirsatiKapat["competitorId"] = newValue.Value;
					this.dataFirsatiKapat["competitorName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			if (this.entityId != null) {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});
				
				try {
					debugger;
					this.CrmDataTable_quote_key++;
					this.CrmDataTable_quote_options.filterParent[0].value = this.entityId;

					this.CrmDataTable_salesorder_key++;
					this.CrmDataTable_salesorder_options.filterParent[0].value = this.entityId;

					this.CrmDataTable_appointment_key++;
					this.CrmDataTable_appointment_options.filterParent[0].value = this.entityId;

					this.CrmDataTable_phonecall_key++;
					this.CrmDataTable_phonecall_options.filterParent[0].value = this.entityId;
					
					let data = await this.crmService.getFirsatById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						
						const pageHistoryItem = { entityLetter: 'SPr', entityName: 'opportunity', ID: this.mainData.entityId, title: this.mainData.baslik + '\r\n\r\n' + this.mainData.potansiyelMusteriName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["potansiyelMusteriId"]) {
							this.$refs.entity_potansiyelmusteri.setDefaultData({ "Value": this.mainData["potansiyelMusteriId"], "Name": this.mainData["potansiyelMusteriName"] });
						}

						if (this.mainData["ilgiliKisiId"]) {
							this.$refs.entity_ilgilikisi.setDefaultData({ "Value": this.mainData["ilgiliKisiId"], "Name": this.mainData["ilgiliKisiName"] });
						}

						if (this.mainData["paraBirimiId"]) {
							this.$refs.entity_parabirimi.setDefaultData({ "Value": this.mainData["paraBirimiId"], "Name": this.mainData["paraBirimiName"] });
						}

						if (this.mainData["tezgahTipiId"]) {
							this.$refs.subject_tezgahtipi.setDefaultData({ [this.mainData["tezgahTipiId"]] : true });
						}

						if (this.mainData["kapanisTarihi"]) {
							this.mainData["kapanisTarihi"] = new Date(this.mainData.kapanisTarihi);
						}

						if (this.mainData["kayitSahibiId"]) {
							this.$refs.entity_kayitSahibi.setDefaultData({ "Value": this.mainData["kayitSahibiId"], "Name": this.mainData["kayitSahibiName"] });
						}
					}

					if (data.kapanisData.length > 0) {
						this.kapanisData = data.kapanisData[0];
					}

					this.tezgahTipiNodes = await this.crmService.GetSubjects();
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
		},
		async OnSave(kapat) {
			debugger;

			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500});
				});
			} else {
				try {
					const response = await this.crmService.getFirsatUpdate(this.mainData);
					debugger;
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							
							if (kapat) {
								if (window.opener) {
									//window.opener.location.reload();
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}
								setTimeout(() => {
									window.close();
								}, 700);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem('opportunity', this.entityId);
        },
		OnRandevuEkle() {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'appointmentnew_withopportunity', params: { id: this.mainData.potansiyelMusteriId, name: this.mainData.potansiyelMusteriName, opportunityId: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		OnTelefonGorusmesiEkle() {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'phonecallnew_withopportunity', params: { id: this.mainData.potansiyelMusteriId, name: this.mainData.potansiyelMusteriName, opportunityId: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		async TeklifEkle() {
			debugger;
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});
			
			try {
				const response = await this.crmService.HizliTeklifOlustur(this.entityId);
				debugger;
				if (response) {
					setTimeout(() => {
						loader.hide();

						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});

							setTimeout(() => {
								let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

								let routeData = this.$router.resolve({ name: 'quote', params: { id: response.olusturulanId } });
								window.open(routeData.href, '_blank', features);
							}, 1000);
						}
					}, 1000);
				}
			} catch (error) {
				setTimeout(() => {
					loader.hide();
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}, 1000);
			}
        },
		isOpen(){
			if (this.mainData.durum == 0) {
				return true;
			}
			else {
				return false;
			}
		},
		TezgahTipiSelect(event) {
			this.mainData["tezgahTipiId"] = event;
		},
		OnFirsatiKapat(durum){
			//1 kazandı
			//2 kaydetti

			if(this.mainData.adet_teklif_etkin == 0) {
				this.displayFirsatiKapat = true;

				this.dataFirsatiKapat.state = durum;
				this.dataFirsatiKapat.actualEnd = new Date();
				this.dataFirsatiKapat.actualrevenue = 0;

				if (durum == 1) { //Kazanıldı ise durum açıklaması Kazanıldı olacak.
					this.dataFirsatiKapat.statusCode = 3;
				}
			}
			else {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen önce Etkin ve Taslak durumunda olan teklifleri kapatın!', life: 5000});
			}
		},
		SM_statuscode(durum) {
			let durumAciklamasi = this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statuscode');

			if (durum == 1) {
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue == 3);
			}
			else if (durum == 2) {
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue > 3);
				durumAciklamasi = durumAciklamasi.filter(x => x.AttributeValue != 930710000);
				//this.dataFirsatiKapat.statusCode = {};
			}

			return durumAciklamasi;
		},
		changeDurum() {
			if (this.dataFirsatiKapat.state == 1) { //Kazandı
				this.dataFirsatiKapat.statusCode = 3;
			}
			else if (this.dataFirsatiKapat.state == 2) { //Kaybetti
				this.dataFirsatiKapat.statusCode = null;
			}

			this.dataFirsatiKapat.competitorId = null;
			this.dataFirsatiKapat.competitorName = null;
		},
		async OnClickFirsatiKapat() {
			if (this.dataFirsatiKapat.statusCode == null) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Durum Açıklamasını doldurunuz!', life: 5000});
				return false;
			}

			if (this.dataFirsatiKapat.actualEnd == null) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Kapanış tarihini doldurunuz!', life: 5000});
				return false;
			}

			if (this.dataFirsatiKapat.state == 1) { //kazanıldı
				if (this.dataFirsatiKapat.actualrevenue == null) {
					this.$toast.add({severity:'error', summary: 'HATA', detail: 'Lütfen Gerçek Geliri doldurunuz!', life: 5000});
					return false;
				}
			}

			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});
			
			try {
				this.dataFirsatiKapat["opportunityId"] = this.entityId;
				const response = await this.crmService.OpportunityClose(this.dataFirsatiKapat);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Fırsat Kapatıldı', life: 3000});
						this.displayFirsatiKapat = false;
						this.dataFirsatiKapat = {};

						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						this.OnLoad();
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
			finally{
				loader.hide();
			}
		},
		OnFirsatiYenidenAc(){
			this.$confirm.require({
                message: 'Satış Projesini tekrar açmak istediğinize emin misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let loader = this.$loading.show({
                        container: this.$refs.smsContainer
                    });

                    try {
                        const postData = {
                            logicalName: 'opportunity',
                            entiyId: this.entityId,
                            stateCode: 0,
                            statusCode: 1
                        };

                        const response = await this.crmService.ChangeStateStatus(postData);
                        debugger;
                        if (response) {
                            if (response.hata == true) {
                                this.$toast.add({severity:'error', summary: 'HATA', detail: response.hataAciklamasi, life: 5000});
                            }else {
                                this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Satış Projesi tekrar aktif hale getirildi.', life: 3000});
								if (window.opener) {
									window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
								}

                                setTimeout(() => {
                                    this.OnLoad();
                                }, 1000);
                            }
                        }
                    } catch (error) {
                        this.$toast.add({severity:'error', summary: 'Bir Hata Oluştu', detail: error.message, life: 3500});
                    }
                    finally {
                        loader.hide();
                    }
                }
            });
		},
		expandAll() {
            for (let node of this.nodes) {
                this.expandNode(node);
            }

            this.expandedKeys = { ...this.expandedKeys };
        },
        collapseAll() {
            this.expandedKeys = {};
        },
		expandNode(node) {
            if (node.children && node.children.length) {
                this.expandedKeys[node.key] = true;

                for (let child of node.children) {
                    this.expandNode(child);
                }
            }
        },
		formatNumber(value) {
            return parseInt(value).toLocaleString('tr-TR');
        },
		styleDurum(durumId){
			if (durumId == 930710000 || durumId == 0) {
				return "durum-badge acik"
			}
			else if (durumId == 4) {
				return "durum-badge kazanildi"
			}
			else {
				return "durum-badge kapali"
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Satış Projeleri');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				baslik: {
					required : helpers.withMessage('Başlık alanı dolu olmalıdır!', required),
				},
				potansiyelMusteriName: {
					required : helpers.withMessage('Potansiyel Müşteri alanı dolu olmalıdır!', required),
				},
				ilgiliKisiName: {
					required : helpers.withMessage('İlgili Kişi alanı dolu olmalıdır!', required),
				},
				urunMarka: {
					required : helpers.withMessage('Ürün Marka alanı dolu olmalıdır!', required),
				},
				kapanisTarihi: {
					required : helpers.withMessage('Kapanış Tarihi alanı dolu olmalıdır!', required),
				},
				paraBirimiName: {
					required : helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
				},
				tezgahAdedi: {
					required : helpers.withMessage('Tezgah Adedi alanı dolu olmalıdır!', required),
				},
				tezgahTipiId: {
					required : helpers.withMessage('Tezgah Tipi alanı dolu olmalıdır!', required),
				},
				projeninDurumu: {
					required : helpers.withMessage('Projenin Durumu alanı dolu olmalıdır!', required),
				},
				kayitSahibiName: {
					required : helpers.withMessage('Kayıt Sahibi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.durum-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		font-weight: bold;
		font-size: 13px;
		letter-spacing: .3px;

		&.kapali {
			background: #FFCDD2;
			color: #C63737;
		}

		&.acik {
			background: #C8E6C9;
			color: #256029;
		}

		&.kazanildi {
			background: #B3E5FC;
			color: #23547B;
		}

		&.revize {
			background: #B3E5FC;
			color: #23547B;
		}

		
	}
</style>
